import React from 'react';
import JSONTable from "../JSONTable";

const PerformanceSummary = ({ data }) => {
    const {
        start: start = '',
        end: end = '',
        data: chartData = [],
        total: total = {},
        query_type: queryType = 'keyword',
    } = data;

    const capitalize = s => (s && String(s[0]).toUpperCase() + String(s).slice(1)) || ""

    const parseNumber = (value) => {
        if (typeof value === "string") {
            return Number(value.replace(/[^0-9.-]+/g, ""));
        }
        return Number(value || 0);
    };

    const { Clicks: totalClicks, Revenue: totalRevenue, Conversions: totalConversions, Cost: totalCost, Impressions: totalImpressions, Interactions: totalInteractions } = total;

    const hasTopKeywords = chartData.length > 9;
    // Limit to top 10  if more than 10 records
    const topXKeywords = chartData.slice(0, 10).map((keyword) => {
        const { Conversions, Revenue, ...rest } = keyword;
        return {
        ...rest,
        ...(totalConversions > 0 && { Conversions: parseInt(Conversions || "0", 10) }), // Conditionally include Conversions
        ...(totalRevenue > 0 && { Revenue }), // Conditionally include Revenue
        };
    });

    // Calculate top X stats
    const topXCost = topXKeywords.reduce((sum, keyword) => sum + parseNumber(keyword.Cost || 0), 0);
    const topXRevenue = topXKeywords.reduce((sum, keyword) => sum + parseNumber(keyword.Revenue || 0), 0);
    const topXClicks = topXKeywords.reduce((sum, keyword) => sum + parseNumber(keyword.Clicks || 0), 0);
    const topXImpressions = topXKeywords.reduce((sum, keyword) => sum + parseNumber(keyword.Impressions || 0), 0);
    const topXInteractions = topXKeywords.reduce((sum, keyword) => sum + parseNumber(keyword.Interactions || 0), 0);

    const formatNumber = (value) => {
        return new Intl.NumberFormat('en-US').format(value);
      };
    
    const costPercentage = ((topXCost / totalCost) * 100).toFixed(2);
    const revenuePercentage = ((topXRevenue / totalRevenue) * 100).toFixed(2);
    const clicksPercentage = ((topXClicks / totalClicks) * 100).toFixed(2);
    const impressionsPercentage = ((topXImpressions / totalImpressions) * 100).toFixed(2);
    const interactionsPercentage = ((topXInteractions / totalInteractions) * 100).toFixed(2);

    const revenuetext = formatNumber(topXRevenue.toFixed(2))

    return (
        <>
            <p>
                Between {start} and {end}, all the {queryType}s have a combined cost of <b>${formatNumber(totalCost.toFixed(2))}</b> and generated $
                <b>{formatNumber(totalRevenue.toFixed(2))}</b>. For performance, they generated <b>{formatNumber(totalClicks)}</b> clicks,{' '}
                <b>{formatNumber(totalImpressions)}</b> impressions, <b>{formatNumber(Math.round(totalConversions))}</b> conversions, and <b>{formatNumber(totalInteractions)}</b> interactions.
            </p> <br />

            {hasTopKeywords && (
                <>
                    <h3><b>Top 10 {capitalize(queryType)}s</b></h3>
                    <p>
                        Relative to the overall, the total top {queryType}s cost <b>${formatNumber(topXCost)}</b>,
                        which is <b>{costPercentage}%</b> of the total cost spent.
                        {
                            topXRevenue > 0 && (
                            <>
                            {' '}<b>${revenuetext}</b> revenue was generated, which accounts for {revenuePercentage}% of the overall revenue from all {queryType}s combined.
                             </>
                        )}
                    </p>
                    <br />
                    <p>
                        Their performance are as follows: <br />
                        - {formatNumber(topXClicks)} clicks ({clicksPercentage}%) compared to the overall clicks <br />
                        - {formatNumber(topXImpressions)} impressions ({impressionsPercentage}%) compared to the overall impressions<br />
                        - {formatNumber(topXInteractions)} interactions ({interactionsPercentage}%) compared to the overall interactions<br />
                    </p>
                    <br />
                </>
            )}

            <p>
                A breakdown of the {queryType}s is shown in the table below:
                <JSONTable data={topXKeywords} />
            </p>
        </>
    );
};

export default PerformanceSummary;
