import React, { useContext } from "react";

import bgImg from "../assets/images/cyber-bg.png";
import { ModalContext } from "../contexts/ModalContext";
import Signup from "./Modal/SignupModal";

import { Button } from "@chakra-ui/react";
import TestimonalSlider from "./Carousel";

const Hero = () => {
  const { openModal } = useContext(ModalContext);
  // const [email, setEmail] = useState('');
  // const [isValidEmail, setIsValidEmail] = useState(true); // Track email validation status

  // const handleEmailChange = (e) => {
  //   setEmail(e.target.value);

  //   const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  //   setIsValidEmail(emailPattern.test(email));
  // };

  // const handleSubscribe = () => {
  //   if (isValidEmail) {
  //     console.log(`Subscribed with email: ${email}`);
  //   } else {
  //     alert('Please enter a valid email address.');
  //   }
  // };

  return (
    <div
      name="home"
      className="flex w-full flex-col justify-between bg-zinc-200 py-16"
      // className="flex w-full flex-col justify-between bg-gradient-to-r from-zinc-200 via-zinc-100 to-white py-16"
    >
      <div className="m-auto grid max-w-[1240px] items-center gap-8 px-4 md:grid-cols-2">
        <div className="flex w-full flex-col justify-center px-2 py-8 md:items-start">
          <h1 className="py-3 text-4xl font-extrabold leading-tight text-gray-800 md:text-6xl">
            Helping Marketers Succeed
          </h1>
          <p className="my-4 text-lg leading-relaxed text-gray-700 md:text-xl">
            We are training an AI Agent for Marketers called{" "}
          <span className="font-bold">Ahoora</span>. The dialogue is
            helping to solve complex marketing problems.{" "}
          <span className="font-bold">Ahoora</span> is currently
            focused on answering Google Ads questions for Paid Search Marketers.
          </p>
          <Button
            onClick={() => openModal(<Signup />)}
            className="mt-4 rounded-lg shadow-lg"
          >
            Ask Ahoora
          </Button>
        </div>
        <div className="relative">
          <img
            className="w-full rounded-lg "
            src={bgImg}
            alt="AI Agent Ahoora Illustration"
            loading="lazy"
          />
        </div>
      </div>
      <TestimonalSlider/>
    </div>
  );
};

export default Hero;
