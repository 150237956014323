import React, { useEffect, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { Box, Heading, Text, VStack, Link } from '@chakra-ui/react';

import ModalContextProvider from '../../contexts/ModalContext';

import Footer from '../../components/Footer/footer';
import Modal from '../../components/Modal';
import Navbar from '../../components/NavigationBar/Navbar';

const posts = [
    {slug: "the-future-of-the-dashboard-is-dynamic"},
    {slug: "the-productive-co-existence-of-marketing-and-ai"},
];

const Blog = () => {

    const [postDetails, setPostDetails] = useState([]);

    useEffect(() => {
        const fetchPostData = async () => {
          // Create a new array to hold the post data
          const postsData = await Promise.all(
            posts.map(async (post) => {
              const { slug } = post;
              try {
                const postData = await import(`../../posts/${slug}.json`);
                
                return { slug, title: postData.title, date: postData.date, first_line: postData.first_line.slice(0, 300), author: postData.author};
              } catch (error) {
                console.error("Error loading post data for:", slug, error);
                return null;  // In case of error, return null for this post
              }
            })
          );
          
          setPostDetails(postsData.filter(post => post !== null));
        };
    
        fetchPostData();
      }, []);

    return (
        <ModalContextProvider>
            <Navbar />
            <Box className="bg-zinc-200" height="100vh">
                <Box as="header" pt="200px" textAlign="center" z-5>
                    <Heading as="h2" size="lg" mb={4}>
                        <Text>All Posts</Text>
                    </Heading>
                </Box>
                <VStack spacing={6} align="stretch" maxWidth="65%" m="0 auto" padding="15px 0">
                    {postDetails.map((blog) => (
                        <Box
                            key={blog.slug}
                            p={4}
                            // borderWidth={1}
                            // borderRadius="md"
                            // boxShadow="md"
                            _hover={{ boxShadow: 'lg' }}
                            transition="all 0.3s ease-in-out"
                        >
                            <Text as="h2" size="md" mb={2} fontSize={24}>
                                {blog.title}
                            </Text>
                            <Text fontSize="sm" color="#555" mb={2}>
                                <em>{blog.date} by {blog.author}</em>
                            </Text>
                            <Text mb={4}>
                                {blog.first_line}...
                            </Text>
                            <Link as={RouterLink} to={`/post/${blog.slug}`} color="#007bff" fontWeight="bold">
                                Read More
                            </Link>
                        </Box>
                    ))}
                </VStack>
            </Box>
            <Footer />
            <Modal />
        </ModalContextProvider>

    );
};

export default Blog;
