import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";

function TestimonalSlider() {
    const CustomArrow = ({ className, style, onClick }) => {
        return (
            <div
                className={className}
                style={{
                    ...style,
                    background: "black",
                    borderRadius: "100%",
                    transition: "none", // Prevent default hover animations
                }}
                onClick={onClick}
            />
        );
    };

    const settings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        pauseOnHover: true,
        nextArrow: <CustomArrow />,
        prevArrow: <CustomArrow />,
    };

    const testimonials = [
        {
            id: 1,
            name: "Emma Brito",
            role: "DIGITAL ACTIVATION DIRECTOR",
            company: "JUNGLE MEDIA",
            image: `emma_b.jpg`,
            quote:
                "The amount of time spent pulling and formatting data to evaluate and report on campaign performance is the biggest pain point we have. While straightforward enough to pull, it can be time-consuming. With Ahoora AI, the WoW reporting is streamlined.",
        },
        {
            id: 2,
            name: "Sam Lueng",
            role: "VP, SEARCH & AD TECHNOLOGIES",
            company: "THE ABER GROUP",
            image: `sam_l.jpg`,
            quote:
                "From a data privacy lens, Ahoora AI is doing something different from the typical approach to AI. Instead of absorbing all of the Google Ads data into their own platform, they're acting as a true midware that sits on top of Google Ads with a 0-data privacy model.",
        },
        {
            id: 3,
            name: "Isaac Uloko",
            role: "DIRECTOR OF MEASUREMENT AND ATTRIBUTION",
            company: "ADM",
            image: `isaac_u.jpg`,
            quote:
                "The ability to use Ahoora AI to get insights quickly to guide strategies provides a high level of efficiency and productivity for search marketers. The user experience derived from its dynamic dashboard that provides insights and reports quickly in a conversational way is a game changer.",
        },
    ];


    return (
        <Box className="m-auto mt-8 max-w-[1240px]" height={'450px'}>
            <Box textAlign="center" mb={6}>
                <Text fontSize="4xl" fontWeight="bold" p={5}>
                    Reviews
                </Text>
            </Box>

            <Slider {...settings}>
                {testimonials.map((testimonial) => (
                    <Box
                        key={testimonial.id}
                        display={{ base: 'block', md: 'flex' }}
                        alignItems="center"
                        borderRadius="lg"
                        p={2}
                        height={'200px'}
                    >
                        <SimpleGrid columns={2} gap="2px">
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="center"
                                flexShrink={0}
                                mb={{ base: 4, md: 0 }}
                                mr={{ md: 6 }}
                            >
                                <Image
                                    src={require(`../../assets/images/` + testimonial.image)}
                                    alt={testimonial.name}
                                    boxSize="80px"
                                    borderRadius="full"
                                    objectFit="cover"
                                    mb={2}
                                />
                                <Box>
                                    <Text fontSize="lg" fontWeight="bold" color="blue.800">
                                        {testimonial.name}
                                    </Text>
                                    <Text fontSize="sm" color="gray.500">
                                        {testimonial.role}
                                    </Text>
                                    <Text fontSize="sm" fontWeight="bold" color="gray.500">
                                        {testimonial.company}
                                    </Text>
                                </Box>
                            </Box>

                            <Box
                                borderLeftWidth="0px"
                                borderColor="none"
                                pl={2}
                                ml={{ md: 0 }}
                                fontStyle="italic"
                                color="gray.700"
                            >
                                <Text fontSize="sm">{testimonial.quote}</Text>
                            </Box>
                        </SimpleGrid>
                    </Box>
                ))
                }
            </Slider >
        </Box >
    );
}

export default TestimonalSlider;